<template>
  <FormWrapper :is-loading="isLoading">
    <PageTitle
      title="
      Trainer Registration Form"
      slot="title"
      link="/helpContent/trainerRegistrationForm"
    />

    <ValidationObserver ref="validator">
      <FormRow>
        <div class="col-sm-1"></div>
        <div class="col-sm-3 formDiv">
          <label class="formLable">Name</label>
        </div>
         <div class="col-sm-1"></div>
        <div class="col-sm-3">
          <inputText  v-model="form.name"   rules="required" :readonly="readOnly" />
        </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-1"></div>
        <div class="col-sm-3 formDiv">
          <label class="formLable">Contract Email</label>
        </div>
         <div class="col-sm-1"></div>
        <div class="col-sm-3">
          <inputText  v-model="form.email"   rules="required" :readonly="readOnly"/>
        </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-1"></div>
        <div class="col-sm-3 formDiv">
          <label class="formLable">Phone Number</label>
        </div>
         <div class="col-sm-1"></div>
        <div class="col-sm-3">
          <InputTel  v-model="form.phone"   rules="required" :readonly="readOnly" />
        </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-1"></div>
        <div class="col-sm-3 formDiv">
          <label class="formLable">Age</label>
        </div>
         <div class="col-sm-1"></div>
        <div class="col-sm-3">
          <inputText  v-model="form.Age"    :readonly="readOnly" />
        </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-1"></div>
        <div class="col-sm-3 formDiv">
          <label class="formLable">DOB</label>
        </div>
         <div class="col-sm-1"></div>
        <div class="col-sm-3">
          <inputDate  v-model="form.DOB"    :readonly="readOnly" />
        </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-1"></div>
        <div class="col-sm-3 formDiv">
          <label class="formLable">Gender</label>
        </div>
         <div class="col-sm-1"></div>
        <div class="col-sm-3">
          <FormSelect  v-model="form.gender"   :items="genderList" rules="required" :disabled="readOnly" 
            item-name="name"
            item-value="id" />
        </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-1"></div>
        <div class="col-sm-3 formDiv">
          <label class="formLable">Location</label>
        </div>
         <div class="col-sm-1"></div>
        <div class="col-sm-3">
          <FormSelect  v-model="form.location"   :items="locationList"
            item-name="name"
            item-value="id"   :disabled="readOnly" :onChange="selectCity"/>
        </div>
      </FormRow>
      
      <FormRow v-if="otherLocation">
        <div class="col-sm-1"></div>
        <div class="col-sm-3 formDiv">
          <label class="formLable">Other Location</label>
        </div>
         <div class="col-sm-1"></div>
        <div class="col-sm-3">
          <inputText  v-model="form.otherLocation"   rules="required" :readonly="readOnly" />
        </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-1"></div>
        <div class="col-sm-3 formDiv">
          <label class="formLable">Highest Education</label>
        </div>
         <div class="col-sm-1"></div>
        <div class="col-sm-7">
          <InputTextArea v-model="form.education"   rules="required" :readonly="readOnly" />
        </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-1"></div>
        <div class="col-sm-3 formDiv">
          <label class="formLable">Company/Organization</label>
        </div>
         <div class="col-sm-1"></div>
        <div class="col-sm-3">
          <inputText v-model="form.company"   rules="required" :readonly="readOnly" />
        </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-1"></div>
        <div class="col-sm-3 formDiv">
          <label class="formLable">Website</label>
        </div>
         <div class="col-sm-1"></div>
        <div class="col-sm-3">
          <inputText v-model="form.website"   rules="required" :readonly="readOnly" />
        </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-1"></div>
        <div class="col-sm-3 formDiv">
          <label class="formLable">Years of Experience</label>
        </div>
         <div class="col-sm-1"></div>
        <div class="col-sm-3">
          <inputIntegerNumber v-model="form.trainingExp"   rules="required" :readonly="readOnly" />
        </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-1"></div>
        <div class="col-sm-3 formDiv">
          <label class="formLable">Please checkmark the responsibilities you can take</label>
        </div>
         <div class="col-sm-1"></div>
        <div class="col-sm-3">
    
        <div v-for="(item, index) in responsibilitCheckBox" :key="index">
    <input type="checkbox" class="checkbox" :id="item.name" v-model="item.checked"  rules="required"  :disabled="readOnly" >
    <label class="checkboxLable"  :for="item.name">{{ item.name }}</label>
  </div>
        </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-1"></div>
        <div class="col-sm-3 formDiv">
          <label class="formLable">Please check the sessions you can take</label>
        </div>
         <div class="col-sm-1"></div>
        <div class="col-sm-3">

    <div v-for="(item, index) in sessions" :key="index">
    <input type="checkbox" class="checkbox" :id="item.name" v-model="item.checked"  :disabled="readOnly">
    <label class="checkboxLable"  :for="item.name">{{ item.name }}</label>
  </div>
        </div>
      </FormRow>
      <FormRow>
          <div class="col-sm-1"></div>
        <div class="col-sm-3 formDiv">
          <label class="formLable">Please check the mode of session delivery</label>
        </div>
         <div class="col-sm-1"></div>
        <div class="col-sm-3">
   <div v-for="(item, index) in sessionsMode" :key="index">
    <input type="checkbox" class="checkbox" :id="item.name" v-model="item.checked"  :disabled="readOnly">
    <label class="checkboxLable"  :for="item.name">{{ item.name }}</label>
  </div> 
  </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-1"></div>
        <div class="col-sm-3 formDiv">
          <label class="formLable">Certifications/Licenses</label>
        </div>
         <div class="col-sm-1"></div>
        <div class="col-sm-3">
          <inputText v-model="form.certifications"   rules="required" :readonly="readOnly" />
        </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-1"></div>
        <div class="col-sm-3 formDiv">
          <label class="formLable">Availability for Training Sessions</label>
        </div>
         <div class="col-sm-1"></div>
        <div class="col-sm-3">
          <FormSelect  v-model="form.availability"   :items="availability"
            item-name="name"
            item-value="id" rules="required" :disabled="readOnly" />
        </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-1"></div>
        <div class="col-sm-3 formDiv">
          <label class="formLable">Bio/Profile Summary</label>
        </div>
         <div class="col-sm-1"></div>
        <div class="col-sm-7">
          <InputTextArea v-model="form.profileSummary"   rules="required" :readonly="readOnly" />
        </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-1"></div>
        <div class="col-sm-3 formDiv">
          <label class="formLable">Profile Picture</label>
        </div>
         <div class="col-sm-1"></div>
        <div class="col-sm-3">
          <div id="preview">
                <img v-if="image" :src="image" class="displayImageFile" />
            </div>
          <!-- <InputFile v-model="form.profilePicture"   :readonly="readOnly"/> -->
          <InputFile :rules="{uploadRequired:false, docCheck:'jpg,jpeg,png', checkFileRegex: true}" :disabled="readOnly" :value="form.profilePicture" name="Profile Picture" label="Photo Upload" @onChange="picUpload"/>
        </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-1"></div>
        <div class="col-sm-3 formDiv">
          <label class="formLable">Resume/CV</label>
        </div>
         <div class="col-sm-1"></div>
        <div class="col-sm-3">
          <div id="preview">
                <!-- <img v-if="file" :src="file" class="displayImageFile" /> -->
                <img v-if="file" :src="require(`Assets/images/PDF.png`)" class="displayPDF" @click="resumeView()" />          
                  </div>
          <InputFile :rules="{uploadRequired:false, docCheck:'PDF,pdf', checkFileRegex: true}" :disabled="readOnly" :value="form.resume" name="Resume Upload" label="Resume Upload" @onChange="fileUpload"/>
        </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-1"></div>
        <div class="col-sm-3 formDiv">
          <label class="formLable">Please enter any additional comments</label>
        </div>
         <div class="col-sm-1"></div>
        <div class="col-sm-7">
          <InputTextArea v-model="form.additionalComments"   rules="required" :readonly="readOnly" />
        </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-1"></div>
        <div class="col-sm-3 formDiv">
          <input type="checkbox" id="termsAndConditionsAcceptance" name="termsAndConditionsAcceptance"  v-model="form.termsAndConditionsAcceptance" @click="terms" :disabled="readOnly">
          <label class="formLable" for="termsAndConditionsAcceptance"> Terms and Conditions Acceptance</label>
          <div class="error" v-if="termsAndConditionsAcceptance">
              Required.
          </div>
        </div>
      
      </FormRow>
   
    </ValidationObserver>
    <br />
    <FormRow>
      <div class="col-sm-6">
        <ButtonGroup>
          <FormButton
            type="info"
            @click="
              () => {
                $router.go(-1);
              }
            "
            >Back</FormButton
          >
          <FormButton type="success" @click="sendData()" :disabled="readOnly">Save</FormButton>
        </ButtonGroup>
      </div>
    </FormRow>
    <Popup
      title="Success"
      type="success"
      :value="showMessage.isVisible"
      @close="() => (showMessage.isVisible = false)"
      :closable="true"
    >
      {{ showMessage.message }}
      <div slot="footer">
        <FormButton
          @click="
            () => {
              $router.go(-1);
            }
          "
          >Ok</FormButton
        >
      </div>
    </Popup>
    <Popup
      title="Information"
      type="info"
      :value="infoMessage.isVisible"
      @close="() => (infoMessage.isVisible = false)"
      :closable="true"
    >
      {{ infoMessage.message }}
    </Popup>
  </FormWrapper>
</template>

<script>
import FormWrapper from "Components/form/FormWrapper";
import PageTitle from "Components/layout/PageTitle";
import loadingMixin from "Mixins/loadingMixin";
import { updateTrainerForm, getTrainerRegistration,resumeUploads,profilePic } from "../api";
import InputDate from "Components/form/InputDate";
import InputTel from "Components/form/InputTel";
import { ValidationObserver } from "vee-validate";
import validateSelectedRows from "Mixins/validateSelectedRows";
import config from 'Config/base';
export default {
  name: "add",
  mixins: [loadingMixin,validateSelectedRows],
  components: {
    FormWrapper,
    PageTitle,
    ValidationObserver,
    InputDate,
    InputTel,
  },
  data() {
    return {
      showMessage: {
        isVisible: false,
      },
      infoMessage: {
        isVisible: false,
      },
      filtering: {
        year: new Date().getFullYear(),
      },
      sorting: {
        key: "id",
        order: "asc",
      },
      pagination: {
        perPage: 10,
        page: 1,
        total: 0,
      },
      form: {
    id:0,				
    name:'',					
    email:'',					
    phone:'',					
    Age:null,					
    DOB:'',					
    gender:'',					
    location:'',				
    education:'',		
    otherLocation:'',		
    company:'',				
    jobtitle:'',				
    website:'',				
    trainingExp:null,			
    responsilbilities:'',		
    sessions:'',				
    sessionMode:'',			
    certifications:'',			
    availability:'',			
    additionalInformation:'',	
    profilePicturePath:null,			
    resumePath:null,				
    profilePicture:null,			
    resume:null,	
    additionalComments:'',		
    profileSummary:'',
    termsAndConditionsAcceptance:false,
      },
      termsAndConditionsAcceptance:false,
      image: null,
      file: null,
      genderList:[
        {'name':'Male',id:'M'},
        {'name':'Female',id:'F'},
      ],
      locationList:[
        {'name':'Delhi',id:'Delhi'},
        {'name':'NOIDA',id:'NOIDA'},
        {'name':'Gaziabad',id:'Gaziabad'},
        {'name':'Faridabad',id:'Faridabad'},
        {'name':'Other',id:'Other'},
      ],
      availability:[
        {'name':'Weekends',id:'Weekends'},
        {'name':'Weekdays',id:'Weekdays'},
        {'name':'Mornings',id:'Mornings'},
        {'name':'Afternoons',id:'Afternoons'},
        {'name':'Evenings',id:'Evenings'},
      ],
      // responsibilitCheckBox:{
      //   checked:true
      // },
      responsibilitCheckBox: [{
        id:1,
        name: 'Design Curriculum',
        value:'Design Curriculum',
        checked: false
      }, {
        id:2,
        name: 'Design Course Content',
        value:'Design Course Content',
        checked: false
      }, {
        id:3,
        name: 'Deliver Training',
        value:'Deliver Training',
        checked: false
      }],
      sessions: [{
        id:1,
        name: 'Data Analytics',
        value:'Data Analytics',
        checked: false
      }, {
        id:2,
        name: 'IOT',
        value:'IOT',
        checked: false
      }, {
        id:3,
        name: 'AI/ML',
        value:'AI/ML',
        checked: false
      },
      {
        id:4,
        name: 'DevOps',
        value:'DevOps',
        checked: false
      },
      {
        id:5,
        name: 'Design Thinking',
        value:'Design Thinking',
        checked: false
      },
      {
        id:6,
        name: 'Soft Skills',
        value:'Soft Skills',
        checked: false
      }
    ],
    sessionsMode: [{
        id:1,
        name: 'Onlines',
        value:'Onlines',
        checked: false
      }, {
        id:2,
        name: 'On Site',
        value:'On Site',
        checked: false
      }],
      readOnly:false,
      otherLocation:false,
    };
  },
  // computed: {
  //   checkedNames () {
  //      return this.names.filter(item => item.checked).map(name => name.name)
  //   }
  // },
  created() {
    if (this.$route.params.id) {
      this.showLoader();
      this.form.id = atob(this.$route.params.id);
      this.form.termsAndConditionsAcceptance=true;
      this.getData();
    }
    if(this.$route.name==='trainerRegistrationListFormView'){
    this.readOnly=true;
    }
  },
  methods: {
//     checkBox1(e){
//       const {checked,defaultValue } = e.target;
// console.log("in",e,checked,defaultValue)
//     },
selectCity(e){
if (e=='Other') {
  this.otherLocation=true;
} else {
  this.otherLocation=false;
}
},
    terms(e){
      const {checked,defaultValue } = e.target;

if(checked){
  this.form.termsAndConditionsAcceptance=true;
}else{
this.form.termsAndConditionsAcceptance=false;
}
    },
    picUpload(file) {
      if(file){
      const formData = new FormData();
      formData.append('photoName',file);
      formData.append('folderName','LFCProfilePics');
      profilePic(formData).then((res)=>{
        if(res.profilePicPath){
this.form.profilePicturePath=res.profilePicPath;
        this.createImage(file);
        } 
      })
                // this.form.profilePicture = file;
                // if(file != null){
                //     this.createImage(file);
                // } else{
                //     this.image = null;
                // }
    }
            },
            resumeView(){
window.open(
  config["imageUrl"]+'wpConnector/' + this.form.resumePath,
  '_blank' )
    },
                fileUpload(file) {
                  if(file){
      const formData = new FormData();
      formData.append('file',file);
      formData.append('folderName','LFCFiles');
      resumeUploads(formData).then((res)=>{
        if(res.resumePath){
        this.form.resumePath=res.resumePath;
        this.createFile(file);
        } 
        })
        }
            },
            createImage(file) {
                let reader = new FileReader();
                reader.onload = (e) => {
                    this.image = e.target.result;
                };
                reader.readAsDataURL(file);
            },
            createFile(file) {
                let reader = new FileReader();
                reader.onload = (e) => {
                    this.file = e.target.result;
                };
                reader.readAsDataURL(file);
            },
            displayProfileImage(){
                this.image = '';
                if(this.form.profilePicturePath){
                   
                    this.image = config['imageUrl']+ this.form.profilePicturePath;
                }
            },
            displayFile(){
                this.file = '';
                if(this.form.resumePath){
                   
                    this.file = config['imageUrl']+ this.form.resumePath;
                }
            },
    getData() {
      const { key, order } = this.sorting;
        const { perPage, page } = this.pagination;

        const filters = {};
        filters.id = this.form.id;

        const data = {
          filterjson: {
            filter: [filters],
            sort: [{ key, order }],
            paging: [
              {
                startIndex: page,
                pagesize: perPage,
              },
            ],
          },
        };
      getTrainerRegistration(data).then((res) => {
			
this.form.name=res.data[0].Name	;				
this.form.email=res.data[0].email	;				
this.form.phone=res.data[0].Phone	;				
this.form.Age=parseInt(res.data[0].Age)	;				
this.form.DOB=res.data[0].DOB	;				
this.form.gender=res.data[0].Gender	;				
this.form.location=res.data[0].Location	;			
this.form.education=res.data[0].Education	;			
this.form.company=res.data[0].Company	;			
this.form.jobtitle=res.data[0].JobTitle	;			
this.form.website=res.data[0].Website;			
this.form.trainingExp=parseInt(res.data[0].TrainingExp);		
// this.form.responsilbilities=res.data[0].Responsilbilities	;	
let responsilbilitiesArr=(res.data[0].Responsilbilities).split(",");
this.responsibilitCheckBox.forEach(item => {
  if(responsilbilitiesArr.indexOf(item.value)!==-1){
  item.checked=true
  }
});

// this.form.sessions=res.data[0].Sessions	;
let session=(res.data[0].Sessions).split(",");
this.sessions.forEach(item => {
  if(session.indexOf(item.value)!==-1){
  item.checked=true
  }
});			
// this.form.sessionMode=res.data[0].SessionMode	;		
let sessionMode=(res.data[0].SessionMode).split(",");
this.sessionsMode.forEach(item => {
  if(sessionMode.indexOf(item.value)!==-1){
  item.checked=true
  }
});	
this.form.certifications=res.data[0].Certifications	;		
this.form.availability=res.data[0].Availability	;		
this.form.additionalInformation=res.data[0].AdditionalInformation	;
this.form.profilePicturePath=res.data[0].ProfilePicturePath	;	
if(res.data[0].ProfilePicturePath){
this.displayProfileImage();
}	
this.form.resumePath=res.data[0].ResumePath	;	
if(res.data[0].ResumePath){
this.displayFile();
}			
this.form.additionalComments=res.data[0].AdditionalComments	;	
this.form.profileSummary=res.data[0].ProfileSummary;
      });
      this.hideLoader();
    },
    // send data to server
    sendData() {
      let responsibilitCheckBox=[];
      let sessions=[];
      let sessionsMode=[];
      this.responsibilitCheckBox.forEach(item => {

  if(item.checked==true){
    responsibilitCheckBox.push(item.value);
  }
});	
this.sessions.forEach(item => {
  if(item.checked==true){
    sessions.push(item.value);
  }
});	
this.sessionsMode.forEach(item => {
  if(item.checked==true){
    sessionsMode.push(item.value);
  }
});	

  //     console.log("res",responsibilitCheckBox)
  //     console.log("res1",sessions)
  //     console.log("res2",sessionsMode)
  //  console.log("in",this.form)
      this.$refs.validator.validate().then((result) => {
        if (result && this.form.termsAndConditionsAcceptance) {
          this.termsAndConditionsAcceptance=false;
          this.showMessage.isVisible = false;
          this.infoMessage.isVisible = false;
          const data = {
            id: this.form.id == 0 ? 0 : parseInt(this.form.id),
            name:this.form.name	,				
email:this.form.email	,				
phone:this.form.phone	,				
Age:this.form.Age	,				
DOB:this.form.DOB	,				
gender:this.form.gender	,				
location:this.otherLocation?this.form.otherLocation:this.form.location,			
// otherLocation:this.form.otherLocation	,			
education:this.form.education	,			
company:this.form.company	,			
jobtitle:this.form.jobtitle	,			
website:this.form.website,			
trainingExp:this.form.trainingExp	,		
responsilbilities:responsibilitCheckBox.join(",")	,	
sessions:sessions.join(","),	
sessionMode:sessionsMode.join(",")	,		
certifications:this.form.certifications	,		
availability:this.form.availability	,		
additionalInformation:this.form.additionalInformation	,
profilePicturePath:this.form.profilePicturePath	,		
resumePath:this.form.resumePath	,				
additionalComments:this.form.additionalComments	,	
profileSummary:this.form.profileSummary,
          };
          updateTrainerForm(data).then((res) => {
            if (res.data[0].status == 1) {
              this.form = {};
              this.showMessage.isVisible = true;
              this.showMessage.message = res.data[0].message;
            } else {
              this.infoMessage.isVisible = true;
              this.infoMessage.message = res.data[0].message;
            }
          });
        }
        else{
      this.termsAndConditionsAcceptance=true;
    }
      });
    },
 
  },
};
</script>
<style lang="scss">
  .error {
    color: #ff3547;
    position: absolute;
    left: 0;
    font-size: 11px;
    line-height: 13px;
    top: calc(100% + 2px);
    font-weight: 400;
  }
.formDiv{
  padding-top: 10px;
}
.formLable{
  color: #515a6e;
  font-size: 15px;
  
}
.checkboxLable{
  color: #515a6e;
  font-size: 14px;
  margin-left: 10px;
}
.checkbox {
        position: relative;
        padding-top: 20px;
    }
    .label {
        display: flex;
    }
    .displayImageFile{
        height: 150px;
        width: 150px;
    }
    .displaPDF{
        height: 150px;
        width: 150px;
    }
</style>
